.adyen-checkout__label {
	position: relative;
}

.adyen-checkout__label__text,
.adyen-checkout__label--focused {
	color: #808080 !important;
}

.adyen-checkout__label__text--error {
	color: #cb333b !important;
}

.adyen-checkout__label__text {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 13px;
	line-height: 1.15;
	font-weight: normal;
	color: #808080;
	padding: 0 0.25rem;
	margin: 0 0 -0.75rem 0.5rem;
	position: relative;
	z-index: 98;
	background-color: white;
	display: inline-block;
}

.adyen-checkout__input {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 15px;
	line-height: 1.13;
	font-weight: normal;
	color: #808080;
}

.adyen-checkout__input,
.adyen-checkout__input--large,
.adyen-checkout__card__cardNumber__input,
.adyen-checkout__input--focus,
.adyen-checkout__input--focus:hover {
	border: 1px solid #333333;
	box-shadow: none;
}

.adyen-checkout__input--small,
.adyen-checkout__input--large,
.adyen-checkout__input--error {
	border: 1px solid #cb333b;
}

.adyen-checkout__input--large {
	max-width: 20rem;
	max-height: 3.5rem;
	height: 3.5rem;
	border-radius: 3px;
	padding: 1rem;
	border: 1px solid #b3b3b3;
}

@media (max-width: 425px) {
	.adyen-checkout__input--large {
		max-width: 16.5rem;
	}
}

.adyen-checkout__card__cardNumber__brandIcon {
	width: 51px;
	height: 100%;
	object-fit: contain;
}

.adyen-checkout__field-wrapper {
	max-width: 15rem;
}

.adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
	margin-left: 1rem;
}

.adyen-checkout__input:focus {
	border-color: #333333;
}

.adyen-checkout__input--small {
	max-width: 8rem;
	max-height: 3.5rem;
	height: 3.5rem;
	border-radius: 3px;
	padding: 1rem;
	border: 1px solid #b3b3b3;
}
.adyen-checkout__card__exp-date__input {
	width: 8rem;
}

.adyen-checkout__field--securityCode {
	width: 6rem;
}

.adyen-checkout__card__cvc__hint,
.adyen-checkout__card__cvc__hint--front,
.adyen-checkout__icon {
	display: none;
}

.adyen-checkout__error-text {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 13px;
	line-height: 1.15;
	font-weight: normal;
	color: #cb333b !important;
	padding: 0 0.25rem;
	margin: 0.5rem 0 0 0.5rem;
	z-index: 2;
}

.adyen-checkout__checkbox__label {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 15px;
	line-height: 1.13;
	font-weight: normal;
	color: #333333;
	padding: 0.25rem 0 0 2rem;
}

.adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:after {
	height: 1.5rem;
	width: 1.5rem;
	border: 1px solid #b3b3b3;
}

.adyen-checkout__checkbox__input:checked
	+ .adyen-checkout__checkbox__label:after {
	border: 1px solid #cb333b;
	background-color: #cb333b;
}

.adyen-checkout__checkbox__input:focus
	+ .adyen-checkout__checkbox__label:after {
	border-color: #808080;
	box-shadow: none;
}

.adyen-checkout__checkbox__input:checked:hover
	+ .adyen-checkout__checkbox__label:after {
	border-color: #cb333b;
	box-shadow: none;
}

.adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:before {
	border-width: 0px 2px 2px 0px;
	border-radius: 0px;
	transform: rotate(45deg);
	height: 13px;
	width: 0.5rem;
	top: 0.25rem;
	left: 3px;
}

.adyen-checkout__store-details {
	margin-bottom: -1rem;
}

.adyen-checkout__paypal {
	margin-top: 2rem;
}

.adyen-checkout__qr-loader--swish img {
	margin: auto;
	display: block;
}

.adyen-checkout__qr-loader img {
	margin: auto;
	display: block;
}

.adyen-checkout__qr-loader__icon--result {
	margin: auto;
	display: block;
}
